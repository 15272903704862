import React from "react"
import tw from "twin.macro"

import PrimaryCta from "@components/PrimaryCta"
import { primaryCtaIh } from "@styles/ui"

const FindADoctorBanner = ({ title, copy, ctaUrl, ctaText }) => {
  return (
    <div tw="md:(w-1/2)">
      <h3 tw="text-2xl font-extrabold text-studio mb-4">{title}</h3>
      <p tw="mb-6 font-light text-base md:(mb-8)">{copy}</p>
      <PrimaryCta
        url={ctaUrl}
        addedStyles={primaryCtaIh}
        width={tw`!w-[10.688rem]`}
      >
        {ctaText}
      </PrimaryCta>
    </div>
  )
}

export default FindADoctorBanner
