import React from "react"
import tw from "twin.macro"

import Container from "@components/container"
import Section from "@components/section"
import PrimaryCta from "@components/PrimaryCta"

import { primaryCtaIh, smallPurpleHeadingIh, sectionIh } from "@styles/ui"

const StayConnectedBannerIh = () => {
  return (
    <Section addedStyles={sectionIh} bgColour={tw`lilac-gold-gradient`}>
      <Container isIh>
        <div tw="bg-white p-6 rounded-tl-[32px] rounded-br-[32px] rounded-tr-[16px] rounded-bl-[16px] lg:(px-[6.25rem] py-8 flex items-center justify-between gap-10)">
          <div tw="max-w-[37.375rem] lg:(w-[61%])">
            <h2 css={[smallPurpleHeadingIh, tw`lg:(mb-4)`]}>
              Get information and stay supported
            </h2>
            <p tw="text-base font-light mb-6 lg:(mb-0)">
              From learning more about your IH, to helping start the
              conversation with your doctor, and exploring XYWAV as a treatment
              option&mdash;take charge of your journey with support every step
              of the way.
            </p>
          </div>
          <div>
            <PrimaryCta
              url="/idiopathic-hypersomnia/stay-connected/"
              addedStyles={[primaryCtaIh, tw`lg:(px-[3.3125rem])`]}
            >
              Sign Up For Emails
            </PrimaryCta>
          </div>
        </div>
      </Container>
    </Section>
  )
}

export default StayConnectedBannerIh
