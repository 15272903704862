import React from "react"
import tw from "twin.macro"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

// Components
import Layout from "@components/layout"
import Section from "@components/section"
import Container from "@components/container"
import Seo from "@components/seo"
import PrimaryCta from "@components/PrimaryCta"
import {
  heading1,
  sectionIh,
  primaryCtaIh,
  smallWhiteHeadingIh,
} from "@styles/ui"
import SecondaryCallout from "@components/SecondaryCallout"
import VideoHero from "@components/videoHero"
import BoxedIconText from "@components/BoxedIconText"
import AmbassadorQuote from "@components/AmbassadorQuote"
import IconSleepiness from "@images/ih/shared/sleeping-person-at-desk-icon.svg"
import IconInertia from "@images/ih/shared/sleeping-person-icon.svg"
import IconCognitive from "@images/ih/shared/brain-icon.svg"
import IconTime from "@images/ih/shared/clock-icon.svg"
import StayConnectedBannerIh from "@components/StayConnectedBannerIh"
import FindADoctorBanner from "@components/FindADoctorBanner"
import ihHeroVideoDesktop from "@videos/Xywav_IH_Homepage_Hero_Video_D.mp4"
import ihHeroVideoMobile from "@videos/Xywav_IH_Homepage_Hero_Video_M.mp4"

const symptoms = [
  {
    icon: IconSleepiness,
    text: <p>Excessive daytime sleepiness</p>,
  },
  {
    icon: IconInertia,
    text: (
      <>
        <p>Sleep inertia</p>
        <p tw="text-xs font-normal mt-1">
          Severe grogginess or confusion when waking up regardless of how much
          sleep you get or how many alarms you set&mdash;feeling disoriented,
          irritable, or even clumsy when waking up and for hours&nbsp;afterwards
        </p>
      </>
    ),
  },
  {
    icon: IconCognitive,
    text: (
      <>
        <p>Cognitive impairment</p>
        <p tw="text-xs font-normal mt-1">
          Difficulty focusing, trouble thinking clearly, or memory problems due
          to excessive daytime sleepiness&#8212;often described as
          &#8220;brain&nbsp;fog&#8221;
        </p>
      </>
    ),
  },
  {
    icon: IconTime,
    text: (
      <>
        <p>Long sleep time</p>
        <p tw="text-xs font-normal mt-1">
          Ability to sleep for many hours of the day&mdash;
          <br tw="hidden lg:block" />
          sometimes for 11 hours or more
        </p>
      </>
    ),
  },
]
const IhHomePage = ({ location }) => {
  return (
    <Layout location={location} mainBgColour="lilac">
      <Seo
        title="XYWAV for Idiopathic Hypersomnia | XYWAV® IH"
        description="Learn about XYWAV® (calcium, magnesium, potassium, and sodium oxybates) and the multiple symptoms of Idiopathic Hypersomnia. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />
      <VideoHero
        hasGradient
        bgColour={tw`bg-lilac`}
        short={true}
        isIh={true}
        containerStyles={tw`lg:(col-span-12)`}
        video={{
          mobile: {
            src: ihHeroVideoMobile,
            additionalStyles: tw`max-sm:(!h-full [video]:(!w-auto)) sm:(top-0 bottom-auto)`,
          },
          desktop: {
            src: ihHeroVideoDesktop,
            additionalStyles: tw`md:(top-auto bottom-[-50px])`,
          },
        }}
        poster={{
          mobile: {
            position: "center top",
            srcPath: "/images/ih/hero_homepage-poster-mobile.jpg",
          },
          desktop: {
            position: "right center",
            srcPath: "/images/ih/hero_homepage-poster.jpg",
          },
        }}
      >
        <h1 css={[heading1, tw`text-4xl pb-2 lg:(text-[3rem] pb-6)`]}>
          Change is possible
          <br />
          with XYWAV
        </h1>
        <p tw="text-white text-xl lg:text-[1.563rem]">
          Ask your doctor about the first and only FDA&#8209;approved
          <br tw="hidden md:block" /> treatment for adults with Idiopathic
          Hypersomnia (IH)
        </p>
      </VideoHero>

      {/* Key points */}
      <Section addedStyles={sectionIh} bgColour={tw`bg-lilac`}>
        <Container isIh>
          <p
            css={tw`relative z-[1] text-3xl font-normal mb-7 md:(mb-10) lg:(text-[1.5625rem])`}
          >
            You could{" "}
            <span tw="text-studio font-extrabold">
              wake up on the bright side.
            </span>{" "}
            XYWAV is a treatment studied across the multiple symptoms of IH,
            such&nbsp;as:
          </p>

          <div tw="lg:(grid grid-cols-2 gap-[1.875rem])">
            {symptoms.map((symptom, index) => {
              return (
                <BoxedIconText
                  key={index}
                  css={[
                    tw`col-span-2 mb-4 lg:(col-span-1 mb-0)`,
                    index % 2 === 0
                      ? tw`lg:(col-start-1)`
                      : tw`lg:(col-start-2)`,
                  ]}
                  icon={symptom.icon}
                  text={symptom.text}
                />
              )
            })}
          </div>
        </Container>
      </Section>

      <Section addedStyles={sectionIh} bgColour={tw`lilac-gold-gradient`}>
        <Container isIh>
          <h2 css={smallWhiteHeadingIh}>
            Ready to learn how to treat your IH?
          </h2>
          <div tw="grid grid-cols-2 gap-6 lg:(gap-[1.875rem])">
            <div tw="bg-white p-6 rounded-tl-[32px] rounded-br-[32px] rounded-tr-[16px] rounded-bl-[16px] col-span-2 lg:(col-span-1 col-start-1 px-8 py-10)">
              <p tw="mb-6 font-extrabold text-lg text-studio lg:(text-xl)">
                You’ve tried different ways to manage your IH.
              </p>
              <p tw="mb-6 text-base font-normal">
                It may be time to try XYWAV, the only medication approved by the
                FDA to treat IH.
              </p>
              <PrimaryCta
                width={tw`!w-[10.75rem]`}
                url="/idiopathic-hypersomnia/treating-ih/"
                addedStyles={primaryCtaIh}
              >
                Why Treat IH?
              </PrimaryCta>
            </div>
            <div tw="bg-white p-6 rounded-tl-[32px] rounded-br-[32px] rounded-tr-[16px] rounded-bl-[16px] col-span-2 lg:(col-span-1 col-start-2 px-8 py-10)">
              <p tw="mb-6 font-extrabold text-lg text-studio lg:(text-xl)">
                Managing your IH with stimulants?
              </p>
              <p tw="mb-6 text-base font-normal">
                Discover XYWAV&mdash;unlike stimulants and wake&#8209;promoting
                agents that are taken during the day, XYWAV is
                different&mdash;it&apos;s taken at night.
              </p>
              <PrimaryCta
                width={tw`!w-[10.75rem]`}
                url="/idiopathic-hypersomnia/what-is-xywav/"
                addedStyles={primaryCtaIh}
              >
                Why XYWAV?
              </PrimaryCta>
            </div>
          </div>
        </Container>
      </Section>

      <Section addedStyles={sectionIh} bgColour={tw`bg-lilac`}>
        <Container isIh>
          <SecondaryCallout
            copy={
              <span tw="block max-w-[37.5rem]">
                Understand how multiple symptoms of IH may affect you throughout
                your day and learn more about how XYWAV may help.
              </span>
            }
            cta="Take the quiz"
            url="/idiopathic-hypersomnia/your-ih-experience/#quiz"
            ctaWidth={tw`min-w-[10.625rem] lg:(min-w-[10.688rem])`}
          />
        </Container>
      </Section>

      <Section addedStyles={sectionIh} bgColour={tw`bg-white`}>
        <Container isIh>
          <AmbassadorQuote
            addedStyles={tw`[p]:(lg:(max-w-[34.0625rem]))`}
            ambassadorName="Diana"
            image={
              <StaticImage
                src="../../images/ih/homepage/xywav-diana-quote.png"
                alt=""
              />
            }
            quote="My life used to be consumed by sleep, but taking XYWAV twice nightly helps me fight daytime sleepiness. I wake up each morning with less sleep inertia and don’t need to nap as much during the day."
            timeFootnote
          />
          <div tw="mt-8 md:(mt-16) md:(px-[6.25rem] flex flex-row-reverse justify-between items-center gap-20)">
            <div tw="mb-6 md:(w-1/2 mb-0)">
              <StaticImage
                src="../../images/ih/homepage/treatment_map.png"
                alt=""
                tw="w-full md:(max-w-[401px])"
              />
            </div>
            <FindADoctorBanner
              title={
                <span tw="block max-w-[17rem] font-medium leading-[1.4] md:max-w-[17rem]">
                  Find a provider who can see if XYWAV may be right for you.
                </span>
              }
              ctaUrl="/idiopathic-hypersomnia/find-a-doctor/"
              ctaText="Find a Doctor"
            />
          </div>
        </Container>
      </Section>

      <StayConnectedBannerIh />
    </Layout>
  )
}

export default IhHomePage

export const query = graphql`
  query {
    thumbImage: file(
      relativePath: { eq: "ih-home/patient-jennie-treatment-video.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    patientVoice: file(
      relativePath: { eq: "ih-home/patient-diana-ih-ambassador-quote.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 705, placeholder: NONE)
      }
    }
    patientVoiceMobile: file(
      relativePath: {
        eq: "ih-home/patient-diana-ih-ambassador-quote-mobile.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 767, placeholder: NONE)
      }
    }
  }
`
